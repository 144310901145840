import React from 'react';
import $ from 'jquery';
import alert from '../images/Alert.gif';
import config from '../config.js';
class Timer extends React.Component{

   constructor(props){
   super(props);
   this.state = {
     status :false,
     runningTime :0,
    clock:"00:00:00" ,
    checked:true
    }
   this.seconds="00";
   this.minutes="0";
   this.hours="0";
   this.time=0;
   this.exportTime=0;
   this.t=0;
   this.add=this.add.bind(this)
   this.timer=this.timer.bind(this);
   this.changetimer=this.changetimer.bind(this);
   this.checkConnection=this.checkConnection.bind(this);
   }


 componentDidMount()
{
  var back = localStorage.getItem('dest');
  this.timer();
}

 checkConnection(){
  if(navigator.onLine){
    $(".connection_status,.code_review_overlay").css("display","none");
   }
  else{
   $(".connection_status,.code_review_overlay").css("display","block");
   setTimeout(this.checkConnection, 1000);
  }
 }
 changetimer(){
 this.setState({status : !this.state.status})
 this.timer();
 }
 add() {
    this.seconds++;
    if ( this.seconds >= 60) {
         this.seconds = 0;
         this.minutes++;
        if ( this.minutes >= 60) {
             this.minutes = 0;
             this.hours++;
        }
    }


     this.time =(this.hours<=9 ? "0"+this.hours : this.hours)+":"+(this.minutes<=9 ? "0"+this.minutes : this.minutes)+":"+(this.seconds<=9 ? "0"+this.seconds : this.seconds);
    this.exportTime =(this.hours<=9 ? "0"+this.hours : this.hours)+(this.minutes<=9 ? "0"+this.minutes : this.minutes)+(this.seconds<=9 ? "0"+this.seconds : this.seconds);
     this.setState({ clock : this.time });
   if(!this.state.status ){
     if(!navigator.onLine){
      $("#codereview_play").trigger('click');
   }
  else{
     this.timer();
     }
  }


}

timer() {
    this.t = setTimeout(this.add, 1000);
}
  render(){
   var userdetails = JSON.parse(localStorage.getItem('user_details'));
   var todo_id=this.props.todoid;
   return (<div className="timer_div">
     <div className="timer"><span className="fa">&#xf017;&nbsp;{this.state.clock}</span></div>
     <div className="btn_font">
       <button className="fa btn-class" id="codereview_play" title={this.state.status ? "play" : "pause" }
         onClick={()=>{
           this.setState({status : !this.state.status})
           if(this.state.status){
             $(".file-sec-overlay").hide();
             this.timer();
             $("#stop_review").removeAttr("disabled");
             $(".list-ele-tree button").css('pointer-events','');

           }
           else{
             $("#stop_review").attr("disabled","true");
             $(".file-sec-overlay").show();
             $(".list-ele-tree button").css('pointer-events','none');
           }
           $("#start_text").html("&#xf04c;");
           $('#start_text').attr('title', 'Resume review');}}>
         {this.state.status ? <i className="fa">&#xf144;</i> : <i className="fa">&#xf28c;</i> }</button>
                <button className="fa btn-class" id="stop_review" title="stop"
                  onClick={()=>{$("#stop_review").css("color","red");
                                $(".stop_review_alert,.code_review_overlay").css("display","block");
                                $("button").attr("disabled","true");
                                $(".stop_review_alert button").removeAttr("disabled");
                                this.setState({status : !this.state.status})}}>&#xf28d;</button>
                <small>{ navigator.onLine ? null : this.checkConnection() }</small>
                 <div className="connection_status">
                           <p>Internet Disconnected....!</p>
                           <div><img src={alert} className="conn_img" alt={"connection failed"}/></div>
                 </div>
                 <div className="stop_review_alert ">
                  <h4 className="align_heading">Are you sure you want to finish review ?</h4>
                  <div>
                   <div className="send_mail">
                     <input className="send_mail_checkbox" type="checkbox"  defaultChecked={this.state.checked}
                            onClick={()=>{
                                            this.setState({checked:!this.state.checked});

                                           }}/>
                     <label>Send Mail</label>
                    </div>
                   <div className="align_review_time ">Total reviewed  time: {this.state.clock}</div>
                  </div>

                    <div className="align_heading">
                      <button className="stop_review_alert_button removed-line"
                              onClick={()=>{ $("#stop_review").css("color","#4c4c4c");
                                             $(".code_review_overlay,.stop_review_alert").css("display","none");
                                             $("button").removeAttr("disabled");
                                             this.setState({status : !this.state.status})
                                             this.timer(); }}>Cancel</button>
                      <button className="stop_review_alert_button added-line" id="target"
                              onClick={()=>{
                                        if(this.state.checked){
   					$.ajax({
                                               type:"POST",
                                               url :config.api_url+"/code_review/timer/"+todo_id+"/"+userdetails['uid']+"/"+userdetails['company_id']+"/"+this.exportTime,
                                              });
                                             }
                                             window.location.href=localStorage.getItem('dest')
                                            }} > Finish</button>
                    </div>

                 </div>

               </div>
            </div>);

 }
}
export default Timer;
