    import React, { Component } from "react";
    import "./Login.css";
    import $ from 'jquery';
    import logo from '../images/login-load.gif';
    import codereview from '../images/codereview.png';
    import config from '../config.js';

    export default class Login extends Component {
	     constructor(props) {
	    super(props);
	    this.state = { username: "", password: "", status: "not-set", login: null, url: window.location.href }
	    this.handleSubmit = this.handleSubmit.bind(this);
	    // this.validate= this.validate.bind(this);
	    this.myApi = this.myApi.bind(this);
	  }
	  componentDidMount() {
	    if (localStorage.getItem("isLoggedIn")) {
	      this.setState({ login: "Authenticated" })
	    }
	  }
	  myApi(formData) {
	    $(".login-load").show();
	    var todo_id = this.state.url.split("=",this.state.url.split("&")[0])[1];
	    var url = config.api_url+"/code_review/login";
	    function getJson(url) {
	      return JSON.parse($.ajax({
		type: "POST",
		data: { username: formData['username'], password: formData['password'], tid: todo_id },
		url: url,
		dataType: 'json',
		global: false,
		async: false,
		success: function (data) {
		  $(".login-load").hide();
		  if (data === "Authentication Failed...!") {
		    alert(data);
		  }
               
		  return data;

		}
	      }).responseText);
	    }

	    var myJsonObj = getJson(url);
	    if (myJsonObj['status'] === 1) {
	       localStorage.setItem("todoid",window.location.href.split("=")[1]);
	      localStorage.setItem("user_details", JSON.stringify(myJsonObj));
	      localStorage.setItem("isLoggedIn", true);
              this.props.data("Authenticated");
	      this.setState({ login: "Authenticated" })
	    }
	  }

	  handleSubmit(e) {
	    e.preventDefault();
	    const formData = [];
	    for (const data in this.refs) {
	      formData[data] = this.refs[data].value;
	    }
	    this.setState({ username: formData['username'], password: formData['password'], status: "set" })
	    this.myApi(formData);
	  }

      render() {
        return (<div className="login-body">
                        <div className="codereview_title"><img src={codereview} /></div>
			<form onSubmit={this.handleSubmit} className="form-container">
			  <div className="container">
			    <label className="uname"><b>Username</b></label>
			    <input type="text" ref="username" placeholder="Enter Username" name="uname" required />
			    <label className="pwd"><b>Password</b></label>
			    <input type="password" ref="password" placeholder="Enter Password" name="psw" required />
			    <div className="load-div"><button type="submit" className="submitbtn">Login<img className="login-load" src={logo} alt="logo" hidden="true" /></button></div>   
			  </div>
                          <div>
                           <p className={(this.state.login === "Authenticated" ? "added-line " : "removed-line ")
                           +"login-status"}>{this.state.login}</p>
                        </div>
			</form>
     		 </div>);
      }
    }


