  import React, { Component } from "react";
  import Fileload from "./fileload.js";
  import Timer from "./timer.js";
  import $ from 'jquery';
  import axios from 'axios';
  import spinner from "../images/spinner.gif";
  import fileload from "../images/fileload.gif";
  import config from '../config.js';


   class ReviewPage extends Component{
    constructor(props){
      super(props);
      this.state={treedata:null,data:null,status:null,filename:null,issues:null,
                  resolved:null,filedata:null,commentline:null,timer_status:false,load:false,firstclick :1,symbol:'+'}
      this.getFileStructureTree = this.getFileStructureTree.bind(this);
      this.recurrsion= this.recurrsion.bind(this);
      this.handleCommentNumber=this.handleCommentNumber.bind(this);
      this.fileHandler=this.fileHandler.bind(this);
      this.setCommentcount=this.setCommentcount.bind(this);
      this.hideFolder = this.hideFolder.bind(this);
      this.getCategories = this.getCategories.bind(this);
     }
    componentDidMount(){
      var user = JSON.parse(localStorage.getItem('user_details'));
      if(window.location.href.split('&')[1]){
      var todo = window.location.href.split('&')[0];
      var destiny = window.location.href.split('&')[1];
      localStorage.setItem('todoid',todo.split("=")[1]);
      localStorage.setItem('dest',destiny.split("=")[1]);
      var todoid = localStorage.getItem('todoid');
      }
      else if(!window.location.href.split('&')[1] && window.location.href.split("=")[1]){
           localStorage.setItem('todoid',window.location.href.split("=")[1]);
           localStorage.setItem('dest',config.api_url);
	   var todoid = localStorage.getItem('todoid');
       }
     else{
         localStorage.setItem('todoid','11111');
         localStorage.setItem('dest',config.api_url);
         var todoid = localStorage.getItem('todoid');
        }
      var self=this;
      var url=config.api_url+"/code_review/treeview/"+todoid+"/"+user['uid'];
	  axios.post(url,
	      {
		    headers :{
		              'Content-Type': 'application/json',
		              'Access-Control-Allow-Origin': url,
		              'Access-Control-Allow-Credentials': true,
	                     }
	      }).then(function(res){
                              self.getCategories();
                              self.setState({treedata:res});
                              res.data['team-lead'] == 1 ?
                              localStorage.setItem("team-lead", true) : localStorage.setItem("team-lead", false);
                              self.getFileStructureTree(res.data['tree']);
                             })
              .catch(error=>{console.log(error)});
           
    }
    getCategories(){
      var user = JSON.parse(localStorage.getItem('user_details'));
      var url=config.api_url+"/code_review/get-categories";
      $.ajax({
        url  : url,
        type : 'POST',
        dataType: 'json',
        data : {cid : user['company_id']},
        success : function(res){ localStorage.setItem("categories", JSON.stringify(res)); },
      });
    }
    hideFolder(id,root){
       $("#"+root+"-"+id+" ul").toggle();
       if($("#"+root+"-"+id+"-btn").text() == "+" ){$("#"+root+"-"+id+"-btn").text("-");}
       else{$("#"+root+"-"+id+"-btn").text("+");}
      }
    recurrsion(e,rootname) {

		return (
			<ul type="none" className="initial-ul">
				{e && e.map((item, i) =>
					item.childNodes == null ? (
						<li className="list-ele-tree" key={"li"+i}>
							<span></span>
							<i className="" aria-hidden="true"></i>
							{item.title.split(":")[1] ?
								(<div title = {item.title.split(":")[0]} className="list-ele-tree">
                      <button id={item.title} key={i} className={"file-btn fa fa-file-text-o file_icon " + item.title.split(":")[1]} 
                              onClick={(e) => { this.fileHandler(e, item.title.split(':')[0]+":"+item.title.split(':')[1],item.title.split(':')[2]);
                  this.setState({load:true});
                  }}>
                    <small className="file_icon" >{" " + item.title.split(":")[0]}</small>
                  </button>

                  <div id={item.title.split(":")[0]}
                    className={this.handleCommentNumber(item.title.split(':')[2]) ?
                    "comment_num" : "comment_num displayHide" }>
                    <small id={item.title.split(":")[0]+"_issues"}
                    className="comment_num_box removed-line" title="Un-resolved">
                      {(item.title.split(':')[2]).split(",")[0]}</small>
                    <small id={item.title.split(":")[0]+"_resolved"}
                    className="comment_num_box added-line" title="Resolved">
                      {(item.title.split(':')[2]).split(",")[1]}</small>
                  </div>
                  </div>)
								: item.title}

                </li>
                ) :
                (
                  <li key={i} className="list-folder" id={rootname+"-"+item.title}>
                    <button className = "folder-toggle" id={rootname+"-"+item.title+"-btn"}
                      onClick = {(e)=>{ this.hideFolder(item.title,rootname);}}>
                      {this.state.symbol}</button>
                    <i className="fa fa-folder folder_icon"></i>
                    {" " + item.title}
                    {item.childNodes && this.recurrsion(item.childNodes,item.title)}
                  </li>
                ))}
              </ul>
		);
	}
    setCommentcount(){
      var todo_id=localStorage.getItem('todoid');
      var file=this.state.filename.split(":")[0];
      axios({
                 method:"POST",
                 url:config.api_url+"/code_review/treeview/comment_num",
                 data:{filename:this.state.filename+":"+todo_id},

           })
           .then((res)=>{this.setState({issues:res.data["issue_number"].split(",")[0],
                                        resolved:res.data["issue_number"].split(",")[1],
                                        commentline:res.data["no_of_commented_lines"]});
                           if(res.data["issue_number"]){
			     var num=Number(res.data["issue_number"].split(',')[0])+Number(res.data["issue_number"].split(',')[1]);
			     if(num>0){
			       if(document.getElementById(file).classList.contains("displayHide")){
				  document.getElementById(file).classList.remove("displayHide");
				 }
				  document.getElementById(file+"_issues").innerHTML=res.data["issue_number"].split(',')[0];
				  document.getElementById(file+"_resolved").innerHTML=res.data["issue_number"].split(',')[1];
				}
			      else{
				if(!(document.getElementById(file).classList.contains("displayHide"))){
				  document.getElementById(file).classList.add("displayHide");
				 }
			      }
			    }
                        })
           .catch((error)=>{console.log(error);})

     }

    fileHandler(e, file, issuecount) {
	e.preventDefault();

        var self=this;
        var todo_id=localStorage.getItem('todoid');
        var fn=file.split(".");
        var ext=fn[0]=="" ? "." : "";
        var i=1;

        for(;i<fn.length;i++){if(i==1){ext +=fn[i];}else{ext +="."+fn[i];}}

        var url=config.api_url+"/code_review/treeview/"+todo_id+"/"+fn[0]+"/"+ext+":"+todo_id;

	 axios.post(url,
	     {
		    headers :{
		               'Content-Type': 'application/json',
		               'Access-Control-Allow-Origin': url,
		                'Access-Control-Allow-Credentials': true,
	                     }
	    }).then(function(res){

                                 self.setState({filedata:res.data['file'],
                                                 commentlines:res.data['cmnt_lines'],load:false
                                                 });
                                   if(self.state.firstclick==1 && localStorage.getItem('team-lead')=='true'){
                                   self.setState({firstclick:"nextclick"});
                                  $(".list-ele-tree button").css('pointer-events','none');
                                   }
                                   else{
                                   $(".file-sec-overlay").css("display","none");
                                   self.setState({firstclick:"nextclick"});
                                   }
                                   $(".close").trigger("click");

                                   })
	      .catch(error=>{console.log(error)})
        this.setState({filename:file,issues:issuecount.split(",")[0],resolved:issuecount.split(",")[1]})

     }
    handleCommentNumber(num){
         var count=Number(num.split(",")[0])+Number(num.split(",")[1]);
         return count>0 ? count : null;
        }

    getFileStructureTree(jsonTree){
       var tree = {};
        tree = jsonTree;
        function toTreeData(tree) {
				    return Object.keys(tree).map(function (title) {
					var o = { title: title };
					if (Object.keys(tree[title]).length > 0) {
					    o.childNodes = toTreeData(tree[title]);
					}
					return o;
				    });
				 }

       this.setState({ data: toTreeData(tree), status: true });
       $('.file-btn.fa.fa-file-text-o.file_icon:first').addClass("highlight");
       $(".file-btn.fa.fa-file-text-o.file_icon:first").trigger('click');

       $('.file-btn.fa.fa-file-text-o.file_icon').click(function () {
         $('.highlight').removeClass('highlight');
         $(this).addClass('highlight');
       });
     }

    render(){
      const tree = this.recurrsion(this.state.data);
      var user = JSON.parse(localStorage.getItem('user_details'));
	      return (<div>
          <div className={ this.state.filedata ? "popup-arrow" : null}>
            <img src={spinner } className="file_load" alt="loading..."/>
          </div>
          <div className={ !window.location.href.split('=')[1] || this.state.filedata ? "tree-container" : "popup-arrow"}>
            <div className={window.location.href.split('=')[1] ? "code_review_overlay" : null}></div>
            <div className="tree-view" >
              <div className="cmnt-edit">
                <button className="user-icon">{user ? user['username'][0].toUpperCase() : null}</button>
                <div className="App-logo">{user ? user['username'].toUpperCase() : null}</div>
                <button className="fa logout comment_box_buttons "
                  onClick={() => { localStorage.clear(); window.location.reload(); }} title="Logout">
                &#xf011;</button>
              </div>
              {localStorage.getItem("isLoggedIn") ?
                (<div className="tree_list_div">
                  <div>{tree}</div>
                </div>) : window.location.reload()}
		        </div>
            <div className="file-content">
              <div>
                <div className="file_header" id="fileheader">
                  <div className="header-left">
                    <h3 className="title_text" >File:
                      <span className="heading_font" title={this.state.filename && " "+this.state.filename.split(":")[0]} >
                        {this.state.filename && " "+this.state.filename.split(":")[0]}
                      </span>
                    </h3>
                  </div>
                  <div className="header-right-comment ">
                    <button className="btn-class btn_space" title="Issues" >Issues:{this.state.issues}</button>
                    <button className="btn-class btn_space" title="Resolved" >Resolved:{this.state.resolved}</button>
                    <button className="setcount" hidden={true} onClick={()=>{this.setCommentcount();}}></button>
                  </div>
                  <div className="header-right-resolve">
                    {this.state.timer_status && localStorage.getItem('team-lead')=='true' ?
                      <Timer ref="timer" todoid={localStorage.getItem('todoid')}/> : null}
                    <button hidden={true} className="dummy_timer"
                      onClick={()=>{if(this.state.timer_status){
                        this.refs.timer.changetimer(); }
                        this.setState({timer_status:true})
                        $(".file-sec-overlay").hide();
                        $("#stop_review").removeAttr("disabled");
                      }}></button>
                  </div>
                </div>

              </div>
              { this.state.load ?
                (<div className="file-sec"><img src={fileload} className="file-load2" /></div>) : <Fileload file={this.state} /> }
            </div>
		      </div>
        </div>);
     }

  }
  export default ReviewPage;
