 import React, { Component } from "react";
 //import axios from 'axios';
 import Popup from "./popup.js";
 import $ from 'jquery';
 
 class Fileload extends Component{
  constructor(props){
   super(props);
   this.state={popup_status:false,active_line:null,filename:null,linenum:null,fileload:"true"}
   this.handleStateChange=this.handleStateChange.bind(this);
   this.fileChange=this.fileChange.bind(this);
   }
  componentDidMount(){
    this.setState({filename:this.props.file['filename']});
   }
 fileChange(){
  this.setState({fileload:"true"});
 }

  handleStateChange(){
    this.setState({popup_status:false})
   }
  render(){
   var key=0;
   //overlay
   return (<div className="hold_overlay">
            <button hidden={true} className="check_filebtn" onClick={()=>{this.setState({fileload:"false"})}}></button>
           { /*!(this.props.file && this.props.file['filedata'])*/
              this.state.fileload==="false"  ? (<div>Loading...!</div>) :
           (<div className="file-sec">
            { localStorage.getItem('team-lead')=='true' ?
             (<div className="file-sec-overlay">
              <button
                       className="Review_start fa" disabled={!this.props.file['filedata'] ? "true" : null}
                        onClick={()=>{$(".dummy_timer").trigger("click");
                                     $(".list-ele-tree button").css('pointer-events','');}}>
                     <span id="start_text" title="Start Review">&#xf04b;</span> 
              </button>
             </div>) : null}
              {this.props.file['filedata'] && this.props.file['filedata'].map((item, j) => {
                     if(j===0){return true;}
                    var icon = (this.props.file['commentlines'] && this.props.file['commentlines'].includes(j.toString())) ? 
                                 "fa fa-circle dot" : null;
                    var bckclr = (item[0] === '+') ? "added-line" : (item[0] === '-') ? "removed-line" : null;
                    key = (item[0] === '@') ? -((item.split("@@T@b")[1]).split(",")[0]) : key;
                    var regex = new RegExp("T@b", "g");
                    //item = item.replace(regex, "\t");
                    item = item.replace(regex, " ")
                    return (
                            <div>
		             <div className={"p-cmnt " + bckclr} id={"line-id" + j} key={"line"+j}>
		              <div className="list-area" key={"line"+j}>
		                <li className="list-ele" data-id={j} >
		                  <div className="list-left" id={"list-left-" + j} key={"list-left-"+j}>
                                   <button className="fa fa-comment-o cmnt" id={"popup-icon-" + j} 
                                     onClick={()=>{
                                                   this.setState({popup_status:true,
                                                                  active_line:"line-id" + j,
                                                                  linenum:j,filename:this.props.file['filename']})
                                                   }}>
                                   </button>
                                  </div >
                                  <div className="li-center" key={"list-center-"+j}> {(item[0] === '@') ? null : (key + j - 2) + "."}</div>
                                  <div className={icon + " dot_icon dot"+j} key={"dot-icon-"+j}></div>
                                  <div className="list-right" key={"list-right-"+j} >
                                     <div>
                                      <pre>{(item[0] === '@' ? item.split('@')[4] : item)}</pre>
                                     </div>
                                  </div> 
                                </li>
                              </div>
                            </div>
                            
                                  {this.state.active_line==="line-id" + j && this.state.popup_status ? 
                                         <Popup propsFunction={this.handleStateChange.bind(this)} line_data={this.state} /> : null}
                             
                            </div> 
                           );

                      })}
            </div>)  }
           </div>);
   } 
 }
 export default Fileload;
 /*<div dangerouslySetInnerHTML={{__html: (item[0] === '@' ? item.split('@')[4] : item)}}></div>*/
