 import React,{ Component } from 'react';
 import Quill from './Quill.js';
 import axios from 'axios';
 import $ from 'jquery';
 import config from '../config.js';
import msg from "../images/msg.gif";
 
 class Popup extends Component{
  constructor(props){
    super(props);
    this.state={line_comments:[],filename:null,linenum:null,edit:null,comment_num:null,
                updated_comment:null,comment_state:null,msg_load:false,categ:[],cid:[]}
    this.getLineComments=this.getLineComments.bind(this);
    this.getQuillValue=this.getQuillValue.bind(this);
    this.submitEditedComment=this.submitEditedComment.bind(this);
    this.deleteComment= this.deleteComment.bind(this);
    this.getCommentUpdate=this.getCommentUpdate.bind(this);
    this.commentResolve=this.commentResolve.bind(this);
   }
  
  componentDidMount(){
       var category = JSON.parse(localStorage.getItem('categories'));
       var cat = new Object();
       var cat_ids = [];
       this.getLineComments();
       cat_ids[0] = '0';
       category.map((item,i)=>{
           var id = item['cid'];
           cat[id] = item['title']+"|"+item['color'];         
          cat_ids[i+1] = id;
         });
         cat['0'] = 'default|#000';
         this.setState({filename:this.props.line_data['filename'],linenum:this.props.line_data['linenum'],
                        msg_load: true,categ:cat,cid:cat_ids})
   }
  commentResolve(url){
   var self = this;
     var todo_id=localStorage.getItem('todoid');
     axios({
               method: 'post',
               url:url,
               data: { linenum: this.props.line_data['linenum'],file:this.props.line_data['filename']+":"+todo_id, msg: null },
              })
              .then(function (response) { 
                    var status=response.data[response.data.length - 1];
                    self.setState({line_comments:response.data,comment_state:status,msg_load: false}) 
                    $(".setcount").trigger("click");})
              .catch(error=>{console.log(error)})
 
   }
  submitEditedComment(commentid,currid){
    
    var self=this;
    var todo_id=localStorage.getItem('todoid');
    var cat = $(".edit_cat_"+currid).val();
    if( $(".ql-editor").text() !== "" &&  this.state.updated_comment !== ""){
    
     axios({
                method: 'post',
                url: config.api_url+"/code_review/comments/req/update/" + commentid,
                data: { linenum: this.props.line_data['linenum'],file:this.props.line_data['filename']+":"+todo_id,
                        msg:this.state.updated_comment,category:cat},
               })
               .then(function (response) {
                                         self.setState({line_comments:response.data,
                                                         edit: !self.state.edit,
                                                          comment_state:response.data[response.data.length-1] })
					self.setState({ msg_load: false });
                                         $(".ql-editor").text(""); 
                                        })
               .catch(error=>{console.log(error)})
    }
   }
  deleteComment(commentid){
    var self=this;
    var todo_id=localStorage.getItem('todoid');
    axios({
                method: 'post',
                url: config.api_url+"/code_review/comments/title/" + commentid,
                data: { linenum: this.props.line_data['linenum'],file:this.props.line_data['filename']+":"+todo_id,
                        msg:this.state.updated_comment },
               })
               .then(function (response) {
                                         self.setState({line_comments:response.data,comment_state:response.data[response.data.length-1]})
                                         if(response.data[0] === "no-comments")
                                           {         
                                                     $(".setcount").trigger("click");
                                                     $(".dot"+self.props.line_data['linenum']).removeClass("fa fa-circle dot");
                                            }
					  self.setState({ msg_load: false });
                                         $(".ql-editor").text(""); 
                                        })
               .catch(error=>{console.log(error)})    
  }
  getCommentUpdate(val){
     this.setState({updated_comment:val})
  }
  getQuillValue(QuillComment){
   var self=this;
   var todo_id=localStorage.getItem('todoid');
   var user = JSON.parse(localStorage.getItem('user_details'));
   var categ = $(".main_cat_select").val();
   if( $(".ql-editor").text() == "" ||  QuillComment == "") {
     self.setState({msg_load: false});
     $(".popup_comment_btn").removeAttr("disabled");
   }
   else if( $(".ql-editor").text() !== "" &&  QuillComment !== ""){
   
    axios({
               method: 'post',
               url: config.api_url+"/code_review/comments/uid/" + user['uid'],
               data: { linenum: this.props.line_data['linenum'],file:this.props.line_data['filename']+":"+todo_id, msg: QuillComment,category: categ},
              })
              .then(function (response) {
                                         self.setState({line_comments:response.data,comment_state:response.data[response.data.length-1],msg_load: false})
                                         $(".setcount").trigger("click");
                                         $(".dot"+self.props.line_data['linenum']).addClass("fa fa-circle dot");
                                         $(".ql-editor").text(""); 
                                         $(".popup_comment_btn").removeAttr("disabled");
                                         $(".main_cat_select").val(0);
                                        })
              .catch(error=>{console.log(error)})
   }
  }
  getLineComments(){
     var self = this;
     var todo_id=localStorage.getItem('todoid');
     axios({
               method: 'post',
               url: config.api_url+"/code_review/comments/getcomment",
               data: { linenum: this.props.line_data['linenum'],file:this.props.line_data['filename']+":"+todo_id, msg: null },
              })
              .then(function (response) { 
                    var status=response.data[response.data.length - 1];
                    self.setState({line_comments:response.data,comment_state:status,msg_load: false}) })
              .catch(error=>{console.log(error)})
   }

  render(){
   var user = JSON.parse(localStorage.getItem('user_details'));
   var user_status = localStorage.getItem("team-lead");
   var a=[];
   var category = JSON.parse(localStorage.getItem('categories'));
   var status_color= this.state.comment_state==="Resolved" ? "resolved" : this.state.comment_state==="Un-Resolved" ? "un-resolved" : null;
   return (
            <div className="custom_popup">
             <div className="review">
              <div className="review-hdng"><p className="cmnt-top">Review</p>{ this.state.msg_load ? (<img src={msg } className="msg_load" alt="loading"/>) : null}</div>
               <div className="cmnt_state">
                <div className={status_color} hidden={this.state.comment_state === "no-comments" ? true : null} id='color'>
                 {this.state.comment_state}
                </div>
               </div>
               <div className="cmnt-close">
                <button className="close close-btn fa fa-times"  title="Close" onClick={()=>{this.props.propsFunction();}}  ></button>
               </div>
             </div>
             {this.state.line_comments && this.state.line_comments.map((item,i)=>{
                 
                 var comment = item.split("d!v|b3@");
                 var color = "",ctype = "";
                 var title = comment[6];

                if( title != undefined && title != "" && (this.state.cid.includes(title))){
                 var categ_grp = this.state.categ[title].split("|");
                 color = categ_grp[1];
                 ctype = categ_grp[0];
                 }
                 a[i]=i;
                 if(i === this.state.line_comments.length-1){return true;}
                 return (
                         <div className="comments_for_line">
                          <div className="cmnt_line_header">
                           <div><i className="fa fa-user user_comment_icon dp-icon" ></i></div>
                            <div className="comment_content">
                             <div id={"quill" + i}
                                 className={this.state.comment_num == a[i] && this.state.edit ?  null : "hidden" } >
                              <Quill data={this.getCommentUpdate.bind(this)}   />
                              <select className={"category edit_cat_"+i} defaultValue={(this.state.cid.includes(comment[6])) ? comment[6] : "0"}>
                                      <option value="0" >Select</option>
                                              {category.map((item,j)=>{
                                                return (<option value={item['cid']} >{item['title']}</option>);
                                               })}
                              </select>
                              <button className="comment_submit edit_btn"
                                      onClick={(e) => {
                                                       this.setState({ edit: !this.state.edit, msg_load: false }); 
                                                       $(".ql-editor").text("");
                                                      }}>
                                                  Cancel</button>

                              <button className="comment_submit edit_btn" id={i}
                                                  onClick={(e) => {this.submitEditedComment(comment[3],i);this.setState({ msg_load: true,});}}>
                                                  Submit</button>
                             </div>
                             <div className="section_two" hidden={this.state.comment_num == a[i] && this.state.edit ? true : null} >
                              <div>
                               <div className="file_header comment_time"></div>
                                                                                        
                                {user['uid'] == comment[5] ? (<div >
                                 <button className="comment_format_options comment_submit fa fa-trash-o remove"
                                          onClick={() => {this.deleteComment(comment[3]);this.setState({ msg_load: true });}}></button>
                                 <button className="comment_format_options comment_submit fa fa-pencil" title={comment[1]} id={i}
                                          onClick={(e) => {
                                                        $("#quill" + i + " div .quill .ql-container .ql-editor").html(comment[1]);
                                                        this.setState({
                                                          edit: !this.state.edit, comment_num: e.target.id,
                                                          updated_comment:comment[1],
                                                          
                                                        }); 
                                                      }}>
                                 </button>
                                </div>) : (<div></div>)}
                               </div>
                               <div className="section_one">
                                <div className={this.state.comment_num == a[i] && this.state.edit ? "hidden" : "comment_reg"}
                                    dangerouslySetInnerHTML={{ __html: comment[1] }}>
                                </div>
                               <div className="comment_time" style={{ display: 'flex'}}>
                                <div className = "cat-icon" style={{ background : color }}>{ctype.substring(0,2).toUpperCase()}</div>
                                <div>#{comment[0]}&nbsp;{comment[2]}</div>
                                <div></div>
                               </div>
                              </div>
                             </div>
                            </div>
                          </div>
                         </div>);
              })}
              <div className="Quill_div">
               <div className= {this.state.edit ? "hover_quill" : null}></div>
               <Quill data={this.getCommentUpdate.bind(this)}  popupstatus={this.state}/>
              </div>
              <div className="comment_box_buttons">
               <select className="category main_cat_select" >
                <option value="0" >Select</option>
                {category.map((item,i)=>{
                   return (<option value={item['cid']} >{item['title']}</option>);
                 })}
               </select>
               <button value={this.state.linenum} className="popup_comment_btn"
                       onClick={()=>{$(".popup_comment_btn").attr("disabled","true");
				     this.setState({ msg_load: true });
                                     this.getQuillValue(this.state.updated_comment);}}>Comment
               </button>
               
            { this.state.comment_state === 'no-comments' ? null :
             user_status==='true' && this.state.comment_state ==="Resolved" ?
              (<button className="comment_reopen"  
                       onClick={()=>{ 
                                      var line_no=this.props.line_data['linenum'];
                                      var url=config.api_url+"/code_review/comments/reopen/"+line_no;
                                      this.commentResolve(url);this.setState({ msg_load: true });
                                     }}>Re-Open</button>)
            : this.state.comment_state ==="Un-Resolved" ?
              (<button className="popup_resolve_btn" 
                       onClick={()=>{
                                      var url=config.api_url+"/code_review/comments/comment_resolve";
                                      this.commentResolve(url);this.setState({ msg_load: true });
                                     }}>Resolve</button>) : null
            }
              </div>
            </div>);
  }
 }
export default Popup;
