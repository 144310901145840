import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import $ from 'jquery';
import Login from "./containers/Login";
import "./containers/review.css";
import ReviewPage from "./containers/ReviewPage";
import * as serviceWorker from './serviceWorker';
import logo from './images/Logo.png';
import config from './config.js';

  class MainPage extends React.Component{
    constructor(props) {
      super(props);
      this.state={login:null}
      this.handleLogin=this.handleLogin.bind(this);
     
    }
  componentWillMount() {
   
  }
  componentDidMount(){
      if(localStorage.getItem("isLoggedIn")){this.setState({login:true});}
      else{this.setState({login:false});}
    }

  handleLogin(val){
     if(localStorage.getItem("isLoggedIn")){this.setState({login:true});}
    }

  render() {
	
          return   (<div>
                     <div id="top-header">
		      <div className="control_icons">
		        <a className="nav-logo" href={config.api_url}>
		           <img src={logo}  />
		        </a>
                      </div>
                     </div>
                     <div className="page_content">
                      {/*window.location.href.split('&')[1] ? this.state.login  ? <ReviewPage /> : <Login data={this.handleLogin.bind(this)} />
                         :(<div>Give some todoId and destination</div>)*/
                        this.state.login  ? <ReviewPage /> : <Login data={this.handleLogin.bind(this)} />
                       }
                     </div>
                   </div> );
       
             }
}

ReactDOM.render(<MainPage />, document.getElementById('root'));

serviceWorker.unregister();
