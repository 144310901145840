import React from 'react';
import ReactQuill from 'react-quill';
//import Delta from 'quill-delta';
import 'react-quill/dist/quill.snow.css';

class Modal extends React.Component {
            constructor(props) {
            super(props);
    this.state={comment_val:""}
          this.handleChange=this.handleChange.bind(this);
          }
   handleChange(value){
            this.setState({ comment_val: value })
          }
          render() {
   var quill_val=this.state.comment_val;
   return (<div onBlur={() => this.props.data(quill_val)}>
            <ReactQuill
              placeholder="write a review..!"
              className="txt-area"
              value={this.state.comment_val}
              onChange={this.handleChange.bind(this)}
            />
           <button className="Quill_comment_btn" onClick={()=>{this.props.data(quill_val);}} hidden="true" ></button>
          </div>);
  }
}
export default Modal;
